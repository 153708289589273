import React from 'react';
import '../css/driver-modal.css';
import IconContainer from './IconsContainer';
import { driversData } from '../assets/data/driversData';

interface DriverModalProps {
  driverIndex: number; // Index to determine which driver data to show
}

const DriverModal: React.FC<DriverModalProps> = ({ driverIndex }) => {
  // Safely fetch the driver data
  const driver = driversData[driverIndex - 1]; // Adjust index for 1-based input

  // Handle cases where the driver data doesn't exist
  if (!driver) {
    return <div className='driver-modal'>Driver not found</div>;
  }

  // Function to handle the click based on the screen size
  const handleClick = () => {
    const isMobile = window.innerWidth < 460; // Check if the screen width is less than 460px

    if (isMobile) {
      // For mobile devices, initiate a phone call
      window.location.href = driver.mainLinkMobile;
    } else {
      // For desktop, open the URL in a new tab
      window.open(driver.mainLink, '_blank');
    }
  };

  return (
    <div className='driver-modal' onClick={handleClick}>
      <div className='driver-modal-title'>
        <img src={driver.profilePic} alt={`${driver.name}'s profile`} />
        <div className='driver-modal-title-text'>
          <h2>{driver.name}</h2>
          <h3>{driver.car}</h3>
        </div>
      </div>
      <div className='driver-modal-info-container'>
        <IconContainer imagePaths={driver.socialIcons} actions={driver.socialLinks} />
        <div className='driver-modal-info'>
          <div className='driver-modal-capacity'>
            <img src={driver.personIcon} alt='Capacity icon' />
            <p>{driver.capacity}</p>
          </div>
          <div className='driver-modal-contact'>
            <img src={driver.phoneIcon} alt='Phone icon' />
            <p>{driver.phone}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverModal;