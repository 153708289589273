import React from 'react';
import '../css/icons-container.css';
import { useNavigate } from 'react-router-dom';

type IconContainerProps = {
  imagePaths: (string | undefined)[];
  actions: (string | (() => void) | undefined)[];
};

const IconContainer: React.FC<IconContainerProps> = ({ imagePaths, actions }) => {
  const navigate = useNavigate();

  const handleClick = (action: string | (() => void) | undefined) => {
    if (typeof action === 'string') {
      if (action.startsWith('http')) {
        window.open(action, '_blank');
      } else if (action) {
        navigate(action);
      }
    } else if (typeof action === 'function') {
      action();
    }
  };

  return (
    <div className="icons-container">
      {imagePaths.map((path, index) =>
        path ? (
          <img
            key={index}
            src={path}
            alt={`icon-${index}`}
            onClick={() => handleClick(actions[index])}
            style={{ cursor: actions[index] ? 'pointer' : 'default' }} // Pointer cursor only if clickable
          />
        ) : null
      )}
    </div>
  );
};

export default IconContainer;