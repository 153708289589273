import React from "react";

const ServicesMain: React.FC = () => {
    return (
        <div className="services-container">
            <div className="services-title">
                <h1>
                    DODATKOWE USŁUGI
                </h1>
                <p>
                Zapewniamy wygodny, bezpieczny i niezawodny transport,
                 dostosowany do Twoich potrzeb. Oto dodatkowe usługi, które oferujemy:
                </p>
            </div>
            <div className="services-group">
                <h2>
                1. Transport na lotniska 
                </h2>
                <div>
                <p>
                Dzięki naszej usłudze transportu na lotniska możesz zapomnieć
                 o stresie związanym z dojazdem i skupić się na swoim wyjeździe.
                </p>
                <ul>
                <li><b>Punktualność</b> zawsze na czas, abyś zdążył na swój lot.</li>
                <li><b>Wygoda</b> nowoczesne, przestronne pojazdy zapewniają komfort podczas każdej podróży.</li>
                <li><b>Obsługa w obu kierunkach </b>  oferujemy zarówno transport na lotniska jak i odbiór w drodze powrotnej.</li>
                </ul>
                <p>Zapewnij sobie bezproblemowy start i zakończenie podróży!</p>
                </div>
            </div>
            <div className="services-group">
                <h2>
                2. Transport imprez okolicznościowych
                </h2>
                <div>
                <p>
                Planujesz wesele, urodziny czy firmową integrację?
                 Zadbaj o to, by wszyscy goście dotarli na miejsce i wrócili bezpiecznie do domów.
                </p>
                <ul>
                <li><b>Dostosowanie do wydarzenia </b>Twoi bliscy lub współpracownicy nie muszą martwić się o transport po udanej zabawie.</li>
                <li><b>Profesjonalizm i kultura </b>nasi kierowcy dbają o wysoki standard obsługi.</li>
                <li><b>Obsługa w obu kierunkach </b>dostosowujemy trasę i godziny do Twoich potrzeb.</li>
                </ul>
                <p>
                    Zamówienie usługi jest proste - wystarczy skontaktować się z nami wcześniej, ustalić datę,
                     godzinę i szczegóły transportu. Resztą zajmiemy się my!
                     Zarezerwuj kierowcę już dziś! 
                </p>
                </div>
            </div>
            <div className="services-group">
                <h2>
                3.Bezpieczny transport dla najmłodszych
                </h2>
                <div>
                <p>
                Nie masz możliwości odebrania dziecka z przedszkola lub szkoły podstawowej?
                 Oferujemy usługi transportu dzieci między placówkami edukacyjnymi a domem dostosowane
                 do potrzeb najmłodszych i ich rodziców.
                </p>
                <ul>
                <li><b>Bezpieczeństwo </b>doświadczeni kierowcy</li>
                <li><b>Punktualność </b>zawsze na czas, by ułatwić Twoją codzienną organizację.</li>
                <li><b>Zaufanie</b> regularny kontakt z rodzicami i dbałość o każdy szczegół podróży.</li>
                </ul>
                <p>
                    Z nami możesz być spokojny, że Twoje dziecko jest w dobrych rękach. 
                </p>
                </div>
            </div>
        </div>
    )
}
export default ServicesMain