import React from "react";
import '../css/fare-modal.css';
import { fareContent } from '../inputs/FareContentVolvo'; // Import the fare content array

// Utility function for bolding text
function parseBoldMarkers(text: string) {
  const parts = text.split(/(\*\*.*?\*\*)/);
  return parts.map((part, index) =>
    part.startsWith("**") && part.endsWith("**") ? (
      <strong key={index}>{part.slice(2, -2)}</strong>
    ) : (
      <span key={index}>{part}</span>
    )
  );
}

const FareModal: React.FC = () => {
  return (
    <div className="fare-modal-container">
      {fareContent.map((fare) => (
        <div key={fare.id} className="fare-modal">
          <h2 className="fare-title">
            {fare.title.main}{" "}
            <span className="font-accent-color">{fare.title.accent}</span>
          </h2>
          <h3 className="fare-price">
            {fare.price.amount}
            <span className="font-accent-color">
              {fare.price.currency}
            </span>
            <span className="fare-price-ending">
              {fare.price.unit}
            </span>
          </h3>
          <p className="fare-description">
            {parseBoldMarkers(fare.description)}
          </p>
          <p className="fare-description">
            {parseBoldMarkers(fare.hour)}
          </p>
          <div className="fare-button-container">
            {fare.contactNumbers.map((number, index) => (
              <div key={index} className="fare-button">
                <a>{number}</a>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FareModal;