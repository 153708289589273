import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css';
import useScroll from '../scripts/useScroll';
import logo from '../assets/img/logo/logo.png';
import menu from '../assets/img/icons/hamburger-menu.png'
import CurtainMenu from './CurtainMenu';

const NavBar: React.FC = () => {
  const isScrolled = useScroll();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };




  return(
    <div>
    <nav className={isScrolled ? 'main-nav nav-scrolled' : 'main-nav'} id='nav-desktop'>
        <div className="nav-backdrop"/>
        <div className="nav-left">
            <Link to="/contacts">
            Kontakt
            </Link>
        </div>
        <div className='nav-center' >
            <Link to='/pricingdodge' className='hover-underline-animation'>
                Cennik Dodge
            </Link>

            
            <Link to="/pricingvolvo">
                Cennik Volvo
            </Link>
            <Link to='/services'>
                Usługi
            </Link>

        </div>
        <div className="nav-right">
            <Link to="/">
            <img src={logo} className='logo'/>
            </Link>
        </div>
    </nav>

    {/* CurtainMenu component */}
    <CurtainMenu isOpen={isMenuOpen} onClose={toggleMenu} />

    <nav className={isScrolled ? 'main-nav nav-scrolled' : 'main-nav'} id='nav-mobile'>
        <div className="nav-backdrop"/>
        <div className="nav-left" onClick={toggleMenu}>
        <img src={menu} className='menu'/>

        </div>
 
        <div className="nav-right">
            <Link to="/">
            <img src={logo} className='logo'/>
            </Link>
        </div>
        

    </nav>

    </div>




  );
}

export default NavBar;

