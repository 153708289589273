
// CurtainMenu.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/curtain-menu.css';

interface CurtainMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const CurtainMenu: React.FC<CurtainMenuProps> = ({ isOpen, onClose }) => {
  return (
    <div className={`curtain-menu ${isOpen ? 'open' : ''}`}>
      <button className="close-btn" onClick={onClose}>×</button>
      <ul className="menu-items">
        <li><Link to="/" onClick={onClose}>Strona Główna</Link></li>
        <li><Link to="/pricingdodge" onClick={onClose}>Cennik Dodge</Link></li>
        <li><Link to="/pricingvolvo" onClick={onClose}>Cennik  Volvo</Link></li>
        <li><Link to="/contacts" onClick={onClose}>Kontakt</Link></li>
        <li><Link to="/services" onClick={onClose}>Usługi</Link></li>

      </ul>
    </div>
  );
};

export default CurtainMenu;