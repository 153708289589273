import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import ScrollToTop from './scripts/scrollToTop';
import './css/main.css';


const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        <AppRoutes />
      </div>
    </Router>
  );
}

export default App;