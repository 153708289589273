import React from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import FareModal from '../components/FareModalDodge';
import '../css/pricing.css'


const PricingDodge: React.FC = () => {
  return (
    <div>
      <Navbar/>
      <div className='pricing-container'>
        <div className='pricing-text'>
          <h1 >
            Cennik Dodge:
          </h1>
          <p>
          Cena każdego kursu rozpoczyna się od opłaty początkowej <br/>
          w wysokości <strong>10.00PLN</strong>, następnie każdy kilometr liczony <br/>
           jest wedle poniższego cennika:
          </p>
        </div>
          <FareModal/>
      </div>
      <div className='pricing-additional-info'>
        <h2>Dodatkowe informacje</h2>
        <ul>
          <li>
          Podział na strefy, o którym mowa w cenniku, opiera się na 
          administracyjnych granicach miasta określonych przez oficjalne tablice.
          </li>
          <li>
          Opłata za godzinę postoju kierowcy wynosi 60PLN
          </li>
        </ul>
        <p>
        Jeśli masz dodatkowe pytania dotyczące cennika lub szczegółów naszych usług, zapraszamy do kontaktu na numer <b>699 102 202</b> chętnie wyjaśnimy wszelkie wątpliwości!
        </p>
      </div>
      <Footer/>
    </div>
  );
}

export default PricingDodge;