import React from 'react';
import Navbar from '../components/NavBar';
import Hero from '../components/Hero';
import DriverModal from '../components/DriverModal';
import Footer from '../components/Footer';


const Home: React.FC = () => {
  return (
    <div>
        <Navbar/>
        <Hero/>
        <Footer/>
    </div>
  );
}

export default Home;