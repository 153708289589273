import React from 'react';
import ContactsBackground from '../assets/img/contacts/contacts-photo.jpg'

import { ReactComponent as LocationIco } from '../assets/img/icons/location-ico.svg';


const ContactsPhoto: React.FC = () => {
  return (
    


    <div className='contacts-photo'>
        <img src={ContactsBackground}/>
        <div className='contacts-photo-text-div'>
            <p>Kontakt</p>
            
        </div>
        <div className='contacts-photo-loaction-div'>
            <LocationIco className='contacts-photo-svg' /> {/* Location tag icon SVG as a component */}
            <p> Dominikańska 1, Bochnia </p>
        </div>



    </div>
    
    
  );
};

export default ContactsPhoto;