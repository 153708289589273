import React from 'react';
import '../css/hero.css';
import HeroVideos from './HeroVideos';
import MainLockup from './MainLockup';

const Hero: React.FC = () => {
  return (

    <div className='hero-container'>
    {/*  <HeroVideos/> */}
      <MainLockup/>    </div>
  );
};

export default Hero;