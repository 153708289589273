import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contacts from './pages/Contacts';
import PricingVolvo from './pages/PricingVolvo';
import PricingDodge from './pages/PricingDodge';
import Services from './pages/Services';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contacts" element={<Contacts/>}/>
      <Route path="/pricingvolvo" element={<PricingVolvo/>} />
      <Route path="/pricingdodge" element={<PricingDodge/>} />
      <Route path="/services" element={<Services/>} />
    </Routes>
  );
}

export default AppRoutes;