import PersonIcon from '../img/driver-modal/person.png'
import PhoneIcon from '../img/driver-modal/phone.png'
import BogdanProfilePic from '../img/driver-modal/bogdan.png'
import RobertProfilePic from '../img/driver-modal/robert.png'
import MapsIcon from '../img/icons/g-maps-icon.png'
import FacebookIcon from '../img/icons/facebook-icon.png'
import BochniaTaxiIcon from '../img/icons/taxi-bochnia-icon.png'






export const driversData = [
    {
      name: 'Bogdan',
      car: 'Dodge Grand Caravan',
      capacity: '1-6',
      phone: '669 102 202',
      profilePic: BogdanProfilePic,
      personIcon: PersonIcon,
      phoneIcon: PhoneIcon,
      mainLink: 'https://maps.app.goo.gl/vgGYpyg7DjbFYkoK8',
      mainLinkMobile: 'tel:669102202', // Mobile link (Initiates phone call)
      socialIcons: [FacebookIcon, MapsIcon],
      socialLinks: ['https://www.facebook.com/bochniataxi', 'https://maps.app.goo.gl/vgGYpyg7DjbFYkoK8']
    },
    {
      name: 'Robert',
      car: 'Volvo S90 (2024)',
      capacity: '1-4',
      phone: '534 014 014',
      profilePic: RobertProfilePic,
      personIcon: PersonIcon,
      phoneIcon: PhoneIcon,
      mainLink: 'https://maps.app.goo.gl/f6PM2LzrwRsrpyPn7',
      mainLinkMobile: 'tel:534014014',  // Mobile link (Initiates phone call)
      socialIcons: [FacebookIcon, MapsIcon],
      socialLinks: ['https://www.facebook.com/taxibochnia' , 'https://maps.app.goo.gl/f6PM2LzrwRsrpyPn7'],
    },
  ];