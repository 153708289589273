import React from "react";
import '../css/map-component.css'


const MapComponent: React.FC = () => {

    return(
        <div className="map-component-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226.82542034171036!2d20.43183864952037!3d49.96941972350295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47163b476fa53c17%3A0xc908ce0baa1e9ac6!2sTaxi%20Bochnia%20-%20post%C3%B3j%20taks%C3%B3wek%20Bochnia!5e0!3m2!1sen!2sis!4v1728422429735!5m2!1sen!2sis"
                allowFullScreen={true}
                aria-hidden="false"
                tabIndex={0}
                style={{ border: 0 }}
            />
        </div>
    )
}
export default MapComponent