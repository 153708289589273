import React from "react";
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import MapComponent from "../components/MapComponent";
import '../css/contacts.css'
import DriverModal from '../components/DriverModal';
import ContactsPhoto from "../components/ContactsPoto";

const Contacts: React.FC = () => {
    
    
    return (
        <div>
            <NavBar/>
            <div className="contacts-container">
                <ContactsPhoto/>
            <div className="contacts-drivers-container">
                <DriverModal driverIndex={1}/>
                <DriverModal driverIndex={2}/>
            </div>
            </div>
            <MapComponent/>
            <Footer/>
        </div>
    )
    
}



export default Contacts