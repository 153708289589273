import React from "react";
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import ServicesMain from "../components/ServicesMain";
import '../css/services.css'

const Services: React.FC = () => {
    return (
        <div>
            <NavBar/>
            <ServicesMain/>
            <Footer/>
        </div>
    )
}

export default Services