import React from 'react';
import { Link } from 'react-router-dom';
import '../css/mainlockup.css';
import '../css/driver-modal.css';
import '../scripts/useHoverEffect';
import DriverModal from '../components/DriverModal';
import MapComponent from '../components/MapComponentMain';



const MainLockup: React.FC = () => {
  
  
  return (

    <div className='hero'>
        <div className='main-lockup' id='hero-title'>
          <div className='main-lockup-text'>
            <h1> 
            Szukasz szybkiej i niezawodnej taksówki w Bochni?
            </h1>
            <h2>
            Oferujemy usługi taxi, dzięki którym zawsze dotrzesz na czas! Nasi profesjonalni kierowcy zapewniają bezpieczne i komfortowe przejazdy w konkurencyjnych cenach.
            Zarezerwuj swoją taksówkę już teraz i ciesz się bezproblemową podróżą!
            </h2>
          </div>
          <div className='main-lockup-buttons-container'>
            <div className='main-lockup-button'>
              <a>
                669 102 202
              </a>
            </div>
            <div className='main-lockup-button'>
              <a>
              534 014 014
              </a>
            </div>
          </div>
          <div className='main-lockup-services'>
            <h2>
                Wśród naszych dodatkowych usług znajdziesz również: 
            </h2>
            <ul>
              <li>
                Transport na lotniska.
              </li>
              <li>
                Transport imprez okolicznościowych.
              </li>
              <li>
                Bezpieczny transport dla najmłodszych między placówkami edukacyjnymi a domem.
              </li>
            </ul>
            <p>
              Po więcej informacji zapraszamy na stronę <Link to="/services" className='custom-link'>usługi.</Link>
            </p>
          </div>
        </div>
        <div className='side-lockup' id='desktop-side-lockup'>

          <div className='driver-modal-container'>
            {/* Render modal for the first driver */}
            <DriverModal driverIndex={1} />

            {/* Render modal for the second driver */}
            <DriverModal driverIndex={2} />
          </div>

            <div className='main-lockup-map-container'>
              <div className='main-lockup-map-title'>
                <div>
                  <h3> BOCHNIA - Postój taksówek </h3>
                  <p> Dominikańska 1, 32-700 Bochnia, Poland</p>
                </div>
                <div>
                  <p>
                    Przejdź na <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" className='custom-link'>Google Maps</a>.
                  </p>
                </div>
              </div>
              <MapComponent/>
            </div>
        </div>
        




        <div className='mobile-main-lockup'>
          <div className='main-lockup' id='hero-title-mobile'>
            <div className='main-lockup-text'>
              <h1> 
              Szukasz szybkiej i niezawodnej taksówki w Bochni?
              </h1>
              <h2>
              Oferujemy usługi taxi, dzięki którym zawsze bezpiecznie i komfortowo dotrzesz na czas!
              Zarezerwuj jednego z poniższych kierowców już teraz!
              </h2>
            </div>
          </div>

          <div className='driver-modal-container'>
            {/* Render modal for the first driver */}
            <DriverModal driverIndex={1} />

            {/* Render modal for the second driver */}
            <DriverModal driverIndex={2} />
          </div>

          <div className='main-lockup-services'>
            <h2>
              Wśród naszych dodatkowych usług znajdziesz również: 
            </h2>
            <ul>
              <li>
                Transport na lotniska.
              </li>
              <li>
                Transport imprez okolicznościowych.
              </li>
              <li>
                Bezpieczny transport dla najmłodszych między placówkami edukacyjnymi a domem.
              </li>
            </ul>
            <p>
              Po więcej informacji zapraszamy na stronę <Link to="/services" className='custom-link'>usługi.</Link>
            </p>
          </div>
          </div>
        </div>

  );
};

export default MainLockup;