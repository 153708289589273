import React from "react";
import '../css/footer.css'
import MapsIcon from '../assets/img/icons/g-maps-icon.png'
import FacebookIcon from '../assets/img/icons/facebook-icon.png'
import BochniaTaxiIcon from '../assets/img/icons/taxi-bochnia-icon.png'
import PhoneIcon from '../assets/img/icons/phone-icon.png'
const Footer: React.FC = () => {


return (


    
    <div>
    <div className="footer-container" id="footer-desktop">
        <div className="main-footer-container">
            <div className="footer-group">
                <img src={BochniaTaxiIcon} className="main-footer-icon"/>
                <p className="main-footer-text">
                <b>Znajdź nas w mediach społecznościowych!</b> <br/>
                Bądź na bieżąco z naszymi promocjami, nowościami i ciekawostkami z Bochni.<br/>
                Dołącz do naszej społeczności i podróżuj z nami wygodnie każdego dnia!
                </p>
                <p className="footer-last-text">Dziękujemy za skorzystanie z naszych usług, polecamy się na przyszłość!</p>
            </div>

            <div className="footer-group"> 
                <h3>Bogdan</h3> 
                <div className="footer-driver-main-group">

                    <div style={{cursor: 'default'}}>
                        <p>669 102 202</p>
                        <img src={PhoneIcon}/>
                    </div>
                    <div   
                    role="button" 
                    onClick={() => window.open('https://www.facebook.com/bochniataxi', '_blank')} 
                    >
                        <p>Bochnia Taxi</p>
                        <img src={FacebookIcon}/>
                    </div>
                    <div   
                    role="button" 
                    onClick={() => window.open('https://maps.app.goo.gl/vgGYpyg7DjbFYkoK8', '_blank')} 
                    >
                        <p>Bochnia Taxi</p>
                        <img src={MapsIcon}/>
                    </div>
                </div>
            </div>
            <div className="footer-group"> 
                <h3>Robert</h3>
                <div className="footer-driver-main-group">

                    <div style={{cursor: 'default'}}>
                        <p>534 014 014</p>
                        <img src={PhoneIcon}/>
                    </div>
                    <div
                        role="button" 
                        onClick={() => window.open('https://www.facebook.com/taxibochnia', '_blank')} 
                    >
                        <p>Taxi Bochnia</p>
                        <img src={FacebookIcon}/>
                    </div>
                    <div   
                    role="button" 
                    onClick={() => window.open('https://maps.app.goo.gl/f6PM2LzrwRsrpyPn7', '_blank')} 
                    >
                        <p>Taxi Bochnia</p>
                        <img src={MapsIcon}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-footer-container">
            <p>
                AMJ © Copyright All Rights Reserved
            </p>
        </div>
    </div>

    <div className="footer-container" id="footer-mobile">
        <div className="main-footer-container">
            <div className="footer-group">
                <img src={BochniaTaxiIcon} className="main-footer-icon"/>
                <p className="main-footer-text">
                <b>Znajdź nas w mediach społecznościowych!</b>
                </p>
            </div>

            <div className="footer-group"> 
                <h3>Bogdan</h3>
                <div className="footer-driver-main-group">

                    <div 
                    role="button" 
                    onClick={() => window.location.href = 'tel:669102202'}
                    >
                        <p>669 102 202</p>
                        <img src={PhoneIcon}/>
                    </div>
                    <div   
                    role="button" 
                    onClick={() => window.open('https://www.facebook.com/bochniataxi', '_blank')} 
                    >
                        <p>Bochnia Taxi</p>
                        <img src={FacebookIcon}/>
                    </div>
                    <div   
                    role="button" 
                    onClick={() => window.open('https://maps.app.goo.gl/vgGYpyg7DjbFYkoK8', '_blank')} 
                    >
                        <p>Bochnia Taxi</p>
                        <img src={MapsIcon}/>
                    </div>
                </div>
            </div>
            <div className="footer-group"> 
                <h3>Robert</h3>
                <div className="footer-driver-main-group">

                    <div 
                    role="button" 
                    onClick={() => window.location.href = 'tel:534014014'}
                    >
                        <p>534 014 014</p>
                        <img src={PhoneIcon}/>
                    </div>
                    <div
                    role="button" 
                    onClick={() => window.open('https://www.facebook.com/taxibochnia', '_blank')} 
                    >
                        <p>Taxi Bochnia </p>
                        <img src={FacebookIcon}/>
                    </div>
                    <div   
                    role="button" 
                    onClick={() => window.open('https://maps.app.goo.gl/f6PM2LzrwRsrpyPn7', '_blank')} 
                    >
                        <p>Taxi Bochnia </p>
                        <img src={MapsIcon}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-footer-container">
            <p>
                AMJ © Copyright All Rights Reserved
            </p>
        </div>
        </div>
    </div>
)
}
export default Footer;