import { Fare } from './types'; // Import the type



export const fareContent: Fare[] = [
  {
    id: 1,
    title: {
      main: "TARYFA",
      accent: "1",
    },
    price: {
      amount: "5.00",
      currency: "PLN",
      unit: "/km",
    },
    hour:"Godzina postojowa kosztuje **60.00PLN**",
    description:
      "Taryfa 1 obowiązuje w strefie miejskiej od **poniedziałku** do **soboty** w godzinach **6.00 - 22.00**.",
    contactNumbers: ["534 014 014"],
  },
  {
    id: 2,
    title: {
      main: "TARYFA",
      accent: "2",
    },
    price: {
      amount: "6.00",
      currency: "PLN",
      unit: "/km",
    },
    hour:"Godzina postojowa kosztuje **60.00PLN**",
    description:
      "Taryfa 2 obowiązuje w strefie miejskiej od **poniedziałku** do **soboty** w godzinach **22.00 - 6.00** rano oraz w **niedziele i święta.**",
    contactNumbers: ["534 014 014"],
  },
  {
    id: 3,
    title: {
      main: "TARYFA",
      accent: "3",
    },
    price: {
      amount: "8.00",
      currency: "PLN",
      unit: "/km",
    },
    hour:"Godzina postojowa kosztuje **60.00PLN**",
    description:
      "Taryfa 3 obowiązuje poza strefą miejską od **poniedziałku** do **soboty** w godzinach **6.00 - 22.00**.",
    contactNumbers: ["534 014 014"],
  },
  {
    id: 4,
    title: {
      main: "TARYFA",
      accent: "4",
    },
    price: {
      amount: "10.00",
      currency: "PLN",
      unit: "/km",
    },
    hour:"Godzina postojowa kosztuje **60.00PLN**",
    description:
      "Taryfa 4 obowiązuje poza strefą miejską od **poniedziałku** do **soboty** w godzinach **22.00 - 6.00** rano oraz w **niedziele i święta.**",
    contactNumbers: ["534 014 014"],
  },
];
